const actions = {
    SET_STATE: 'services/SET_STATE',
    FETCH_ALL_SERVICES: 'services/FETCH_ALL_SERVICES',
    ADD_SERVICE: 'services/ADD_SERVICE',
    EDIT_SERVICE: 'services/EDIT_SERVICE',
    ACTIVATE_SERVICE: 'services/ACTIVATE_SERVICE',
    DELETE_SERVICE: 'services/DELETE_SERVICE',
    HANDLE_ADD_MODAL: 'services/HANDLE_ADD_MODAL',
    HANDLE_EDIT_MODAL: 'services/HANDLE_EDIT_MODAL'
}

export default actions


