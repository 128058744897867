import { takeEvery, all, put } from "redux-saga/effects";
import { notification } from "antd";
import { history } from "index";
import IAdminService from "services/iadmin";
import { doApiCall } from "redux/helper";
import actions from "./action";

const iAdminService = new IAdminService();

export function* DELETE_VENDOR({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.deleteVendor,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    notification.warning({
      message: "Deactivated",
      description: "Admin has deactivated Vendor.",
    });
    yield put({
      type: "iadmin/VENDOR_BY_ID",
      payload: {
        query,
      },
    });
  }
}

export function* ACTIVATE_VENDOR({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.activateVendor,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    notification.success({
      message: "Activated",
      description: "Admin has Activated Vendor.",
    });
    yield put({
      type: "iadmin/VENDOR_BY_ID",
      payload: {
        query,
      },
    });
  }
}

export function* DELETE_VENDOR_TYPE({ payload: { body } }) {
  const response = yield doApiCall(
    iAdminService.deleteVendortype,
    { payload: { body } },
    actions.SET_STATE
  );

  if (response.success) {
    yield put({
      type: "iadmin/FETCH_ALL_VENDOR_TYPE",
    });
    notification.warning({
      message: "Deactivated",
      description: "Admin has Deactivated Vendor Type.",
    });
  }
}

export function* ACTIVATE_VENDOR_TYPE({ payload: { body } }) {
  const response = yield doApiCall(
    iAdminService.activatevendortype,
    { payload: { body } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "iadmin/FETCH_ALL_VENDOR_TYPE",
    });
    notification.success({
      message: "Activated",
      description: "Admin has Activated Vendor.",
    });
  }
}

export function* GET_ALL_USERS({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.getAllUser,
    { payload: { query } },
    actions.SET_STATE
  );
  if (response.success) {
    const { data, meta } = response;
    yield put({
      type: "iadmin/SET_STATE",
      payload: {
        users: data,
        pagination: meta.pagination,
      },
    });
    history.push("/dashboard");
  }
}

export function* GET_ALL_VENDOR({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.getAllVendor,
    { payload: { query } },
    actions.SET_STATE
  );
  if (response.success) {
    const { data, meta } = response;
    yield put({
      type: "iadmin/SET_STATE",
      payload: {
        vendors: data,
        pagination: meta.pagination,
      },
    });
  }
}

export function* VENDOR_BY_ID({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.getVendorById,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iadmin/SET_STATE",
      payload: {
        currentvendor: data,
      },
    });
  }
}

export function* EDIT_VENDOR({ payload: { body, query } }) {
  const response = yield doApiCall(
    iAdminService.editVendor,
    { payload: { body } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "iadmin/GET_ALL_VENDOR",
      payload: {
        query: {
          page: query.page,
          perPage: query.perPage,
        },
      },
    });
    notification.success({
      message: "Vendor Edit Successfully",
      description: "Vendor get Editted successfully",
    });
    history.push("/admin/vendor");
  }
}

export function* COMPANY_SERVICES({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.fetchCompanyServices,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iuser/SET_STATE",
      payload: {
        currentServices: data,
      },
    });
  }
}

export function* ADD_VENDOR({ payload: { body } }) {
  const response = yield doApiCall(
    iAdminService.addvendor,
    { payload: { body } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "iadmin/GET_ALL_VENDOR",
      payload: {
        query: {
          page: 1,
          perPage: 15,
        },
      },
    });
    notification.success({
      message: "Vendor Added Successfully",
      description: "New Vendor is Added!",
    });
    history.push("/admin/vendor");
  }
}

export function* ADD_VENDOR_TYPE({ payload: { body } }) {
  const response = yield doApiCall(
    iAdminService.addvendortype,
    { payload: { body } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "iadmin/FETCH_ALL_VENDOR_TYPE",
    });
    notification.success({
      message: "Vendor Type Added Successfully",
      description: "New Vendor Type is Added!",
    });
    yield put({
      type: "vendor/HANDLE_MODAL_ADD",
      payload: {
        query: {
          visibility: false,
        },
      },
    });
  }
}

export function* FETCH_ALL_VENDOR_TYPE() {
  const response = yield doApiCall(
    iAdminService.fetchvendortype,
    {},
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iadmin/SET_STATE",
      payload: {
        vendorType: data,
      },
    });
  }
}

export function* FETCH_VENDOR_TYPE() {
  const response = yield doApiCall(
    iAdminService.fetchAllVendorType,
    {},
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iadmin/SET_STATE",
      payload: {
        vendorType: data,
      },
    });
  }
}

export function* MANAGE_VENDOR_TYPE({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.managevendorType,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data, meta } = response;
    yield put({
      type: "iadmin/SET_STATE",
      payload: {
        vendorType: data,
        pagination: meta.pagination,
      },
    });
  }
}

export function* EDIT_VENDOR_TYPE({ payload: { body, serviceId } }) {
  const response = yield doApiCall(
    iAdminService.editvendortype,
    { payload: { body, serviceId } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "iadmin/FETCH_ALL_VENDOR_TYPE",
    });
    notification.success({
      message: " Success",
      description: "Vendor Type Edited Successfully!",
    });
    yield put({
      type: "vendor/HANDLE_MODAL_EDIT",
      payload: {
        query: {
          visibility: false,
        },
        body: {
          serviceObj: {},
        },
      },
    });
  }
}
export function* ACTIVATE_USER({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.activateUser,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    notification.success({
      message: "Activated",
      description: "User has been activated.",
    });
    yield put({
      type: "iadmin/GET_USER_BY_ID",
      payload: {
        query,
      },
    });
  }
}

export function* DELETE_USER({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.deleteUser,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    notification.warning({
      message: "Deactivated",
      description: "User has been deactivated.",
    });
    yield put({
      type: "iadmin/GET_USER_BY_ID",
      payload: {
        query,
      },
    });
  }
}

export function* GET_USER_BY_ID({ payload: { query } }) {
  const response = yield doApiCall(
    iAdminService.getUSerById,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iadmin/SET_STATE",
      payload: {
        currentuser: data,
      },
    });
    yield put({
      type: "iadmin/COMPANY_SERVICES",
      payload: {
        query: {
          id: query.id,
        },
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_VENDOR_TYPE, FETCH_ALL_VENDOR_TYPE),
    takeEvery(actions.GET_USER_BY_ID, GET_USER_BY_ID),
    takeEvery(actions.FETCH_VENDOR_TYPE, FETCH_VENDOR_TYPE),
    takeEvery(actions.MANAGE_VENDOR_TYPE, MANAGE_VENDOR_TYPE),
    takeEvery(actions.COMPANY_SERVICES, COMPANY_SERVICES),
    takeEvery(actions.DELETE_USER, DELETE_USER),
    takeEvery(actions.ACTIVATE_USER, ACTIVATE_USER),
    takeEvery(actions.ACTIVATE_VENDOR_TYPE, ACTIVATE_VENDOR_TYPE),
    takeEvery(actions.DELETE_VENDOR_TYPE, DELETE_VENDOR_TYPE),
    takeEvery(actions.DELETE_VENDOR, DELETE_VENDOR),
    takeEvery(actions.ACTIVATE_VENDOR, ACTIVATE_VENDOR),
    takeEvery(actions.GET_ALL_USERS, GET_ALL_USERS),
    takeEvery(actions.GET_ALL_VENDOR, GET_ALL_VENDOR),
    takeEvery(actions.EDIT_VENDOR, EDIT_VENDOR),
    takeEvery(actions.ADD_VENDOR, ADD_VENDOR),
    takeEvery(actions.ADD_VENDOR_TYPE, ADD_VENDOR_TYPE),
    takeEvery(actions.EDIT_VENDOR_TYPE, EDIT_VENDOR_TYPE),
    takeEvery(actions.VENDOR_BY_ID, VENDOR_BY_ID),
  ]);
}
