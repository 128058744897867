import apiClient from "../axios";

class IUserService {
  fetchCompanyServices = ({ query }) => {
    return apiClient.get(`users/${query.id}/companyservices`);
  };

  getAllUser = ({ query }) => {
    return apiClient.get(
      `users?page=${query.page}&perPage=${query.perPage}&include=status`
    );
  };

  getUserById = ({ query }) => {
    return apiClient.get(`users/${query.id}`);
  };

  fetchAllVendorType = () => {
    return apiClient.get("vendortype/all");
  };

  getEditCompanyServices = () => {
    return apiClient.get("users/services/userservices");
  };

  userEdit = ({ body }) => {
    return apiClient.patch("users/profile/edit", body);
  };

  activateService = ({ body }) => {
    return apiClient.patch("users/profile/companyservices/", body.values);
  };

  deleteService = ({ body }) => {
    return apiClient.patch("users/profile/companyservices/rm", body.values);
  };

  addvendor = ({ payload }) => {
    return apiClient.post("/vendor", payload.body.values);
  };
}

export default IUserService;
