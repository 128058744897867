import actions from "./action";

const initialState = {
  users: [],
  vendors: [],
  currentuser: {},
  currentvendor: {},
  vendorType: [],
  pagination: {},
  formErrors: {},
  view: false,
};

export default function iadminReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
