import apiClient from "../axios";

class AdminService {
  adminLogin = ({ body }) => {
    return apiClient.post("/admin/auth/login", body.values);
  };

  approveUser = ({ payload }) => {
    return apiClient.post(`/admin/auth/${payload.query.userId}/verification`);
  };

  disapproveUser = ({ payload }) => {
    return apiClient.patch(`admin/users/${payload.query.userId}/deactivate`);
  };

  getAllUsers = ({ payload }) => {
    return apiClient.get(
      `admin/dashboard?page=${payload.pager.page}&perPage=${payload.pager.perPage}`
    );
  };

  getUnverifiedUsers = ({ payload }) => {
    return apiClient.get(
      `admin/users/unverifiedusers?page=${payload.pager.page}&perPage=${payload.pager.perPage}`
    );
  };

  getUnverifiedVendor = ({ pager }) => {
    return apiClient.get(
      `admin/vendor/unverified?page=${pager.page}&perPage=${pager.perPage}`
    );
  };

  getAllServices = ({ payload }) => {
    return apiClient.get(
      `admin/companyservices?page=${payload.query.page}&perPage=${payload.query.perPage}`
    );
  };

  getOneServices = (serviceId) => {
    return apiClient.get(`admin/companyservices/${serviceId}`);
  };

  addService = ({ body }) => {
    return apiClient.post("admin/companyservices/", body.values);
  };

  editService = ({ body }) => {
    return apiClient.patch(`admin/companyservices/${body.id}`, body.values);
  };

  activateService = ({ id }) => {
    return apiClient.get(`admin/companyservices/${id}/activate`);
  };

  deleteService = ({ id }) => {
    return apiClient.delete(`admin/companyservices/${id}`);
  };
}

export default AdminService;
