import apiClient from "../axios";

class vendorServices {
  getAllVendor = ({ query }) => {
    return apiClient.get(`vendor?page=${query.page}&perPage=${query.perPage}`);
  };

  getVendorById = ({ query }) => {
    return apiClient.get(`vendor/${query.id}`);
  };

  getAllVendorType = () => {
    return apiClient.get("vendortype");
  };

  fetchAllVendorType = () => {
    return apiClient.get("vendortype/all");
  };

  searchVendor = ({ body }) => {
    const url = body.isAdmin ? "/admin/vendor/search/" : "/vendor/search";
    return apiClient.post(url, body);
  };

  deleteVendor = ({ query }) => {
    return apiClient.patch(`admin/vendor/${query.id}/deactivate`);
  };

  activateVendor = ({ query }) => {
    return apiClient.patch(`admin/vendor/${query.id}/activate`);
  };
}

export default vendorServices;
