import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Avatar } from "antd";
import "./style.module.scss";

const mapStateToProps = ({ user }) => ({ user, status: user.status });

const ProfileMenu = ({ dispatch, user, status }) => {
  const logout = (e) => {
    e.preventDefault();
    dispatch({
      type: "user/LOGOUT",
      payload: user.role,
    });
  };

  const getProfileImage = () => {
    if (user.avatar === null) {
      return null;
    }
    return process.env.REACT_APP_BASE_MEDIA_URL + user.avatar;
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />,{" "}
          {user.name || "Anonymous"}
        </strong>
        <div>
          <strong className="mr-1">
            <FormattedMessage id="topBar.profileMenu.companyName" />:{" "}
          </strong>
          {user.companyName || "-"}
        </div>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.designation" />:{" "}
          </strong>
          {user.designation || "—"}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{" "}
          </strong>
          {user.email || "—"}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{" "}
          </strong>
          {user.phoneNumber || "—"}
        </div>
      </Menu.Item>
      {user.role === 0 && status === 1 && (
        <>
          <Menu.Divider />
          <Menu.Item>
            <Link to="/user/edit">
              <i className="fe fe-user mr-2" />
              <FormattedMessage id="topBar.profileMenu.editProfile" />
            </Link>
          </Menu.Item>
        </>
      )}
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div className="dropdown">
        {" "}
        <Avatar
          className="avatar"
          shape="square"
          size="large"
          icon={<UserOutlined />}
          src={getProfileImage()}
        />
      </div>
    </Dropdown>
  );
};

export default connect(mapStateToProps)(ProfileMenu);
