import { all, takeEvery, put } from "redux-saga/effects";
import { doApiCall } from "redux/helper";
import { notification } from "antd";
import VendorServices from "../../services/vendor";
import actions from "./actions";

const vendorServices = new VendorServices();

export function* ALL_VENDOR({ payload: { query } }) {
  const response = yield doApiCall(
    vendorServices.getAllVendor,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data, meta } = response;
    yield put({
      type: actions.SET_STATE,
      payload: {
        vendor: data,
        pagination: meta.pagination,
      },
    });
  }
}

export function* ALL_VENDOR_TYPE() {
  const response = yield doApiCall(
    vendorServices.getAllVendorType,
    {},
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: actions.SET_STATE,
      payload: {
        vendorType: data,
      },
    });
  }
}

export function* FETCH_ALL_VENDOR_TYPE() {
  const response = yield doApiCall(
    vendorServices.fetchAllVendorType,
    {},
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: actions.SET_STATE,
      payload: {
        vendorType: data,
      },
    });
  }
}

export function* VENDOR_BY_ID({ payload: { query } }) {
  const response = yield doApiCall(
    vendorServices.getVendorById,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "vendor/SET_STATE",
      payload: {
        currentvendor: data,
      },
    });
  }
}

export function* SEARCH_VENDOR({ payload: { body } }) {
  const response = yield doApiCall(
    vendorServices.searchVendor,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    const { data, meta } = response;
    if (body.isAdmin) {
      yield put({
        type: "iadmin/SET_STATE",
        payload: {
          vendors: data,
          pagination: meta.pagination,
        },
      });
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          vendor: data,
          pagination: meta.pagination,
        },
      });
    }
  }
}

export function* HANDLE_MODAL_ADD({ payload: { query } }) {
  yield put({
    type: "vendor/SET_STATE",
    payload: {
      MODAL_ADD: query.visibility,
    },
  });
}

export function* HANDLE_MODAL_EDIT({ payload: { body, query } }) {
  yield put({
    type: "vendor/SET_STATE",
    payload: {
      MODAL_EDIT: query.visibility,
      serviceObj: body.serviceObj,
    },
  });
}

export function* DELETE_VENDOR({ payload: { query, pager } }) {
  const response = yield doApiCall(
    vendorServices.deleteVendor,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    notification.warning({
      message: "Deactivated",
      description: "Admin has deactivated Vendor.",
    });
    yield put({
      type: "admin/GET_UNVERIFIED_VENDOR",
      payload: {
        pager,
      },
    });
  }
}

export function* ACTIVATE_VENDOR({ payload: { query, pager } }) {
  const response = yield doApiCall(
    vendorServices.activateVendor,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    notification.success({
      message: "Activated",
      description: "Admin has Activated Vendor.",
    });
    yield put({
      type: "admin/GET_UNVERIFIED_VENDOR",
      payload: {
        pager,
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ALL_VENDOR, ALL_VENDOR),
    takeEvery(actions.FETCH_ALL_VENDOR_TYPE, FETCH_ALL_VENDOR_TYPE),
    takeEvery(actions.VENDOR_BY_ID, VENDOR_BY_ID),
    takeEvery(actions.ALL_VENDOR_TYPE, ALL_VENDOR_TYPE),
    takeEvery(actions.SEARCH_VENDOR, SEARCH_VENDOR),
    takeEvery(actions.HANDLE_MODAL_EDIT, HANDLE_MODAL_EDIT),
    takeEvery(actions.HANDLE_MODAL_ADD, HANDLE_MODAL_ADD),
    takeEvery(actions.DELETE_VENDOR, DELETE_VENDOR),
    takeEvery(actions.ACTIVATE_VENDOR, ACTIVATE_VENDOR),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
