import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { connect } from "react-redux";

import Layout from "layouts";
import ROUTES from "constants/routes";

const routes = [
  // VB:REPLACE-START:ROUTER-CONFIG
  // {
  //   path: "/dashboard",
  //   Component: lazy(() => import("pages/dashboard")), // user
  //   exact: true,
  // },

  // VB:REPLACE-END:ROUTER-CONFIG
  {
    path: ROUTES.ADMIN_DASHBOARD,
    Component: lazy(() => import("components/Dashboard/Admin")),
    exact: true,
  },
  {
    path: ROUTES.ADMIN_SERVICES,
    Component: lazy(() =>
      import("components/CompanyServices/ManageCompanyServices")
    ),
    exact: true,
  },
  {
    path: ROUTES.USER_LOGIN,
    Component: lazy(() => import("pages/auth/login")),
    exact: true,
  },
  {
    path: ROUTES.ADMIN_LOGIN,
    Component: lazy(() => import("components/Auth/AdminLogin")),
    exact: true,
  },
  {
    path: ROUTES.USER_EDITPROFILE,
    Component: lazy(() => import("components/Users/EditUser")),
    exact: true,
  },
  {
    path: ROUTES.USER_DIRECTORY,
    Component: lazy(() => import("pages/admin/directory/users")), // admin
    exact: true,
  },
  {
    path: ROUTES.USER_RESET_PASSWORD,
    Component: lazy(() => import("components/Auth/ResetPassword")),
    exact: true,
  },
  {
    path: ROUTES.USER_FORGOT_PASSWORD,
    Component: lazy(() => import("pages/auth/forgot-password")),
    exact: true,
  },
  {
    path: ROUTES.USER_REGISTER,
    Component: lazy(() => import("pages/auth/register")),
    exact: true,
  },
  {
    path: ROUTES.PAGE_NOT_FOUND,
    Component: lazy(() => import("pages/auth/404")),
    exact: true,
  },
  {
    path: ROUTES.FORBIDDEN,
    Component: lazy(() => import("pages/auth/403")),
  },
  {
    path: ROUTES.INTERNAL_SERVER_ERROR,
    Component: lazy(() => import("pages/auth/500")),
    exact: true,
  },
  {
    path: ROUTES.ADMIN_VENDOR,
    Component: lazy(() => import("pages/admin/directory/vendor")), // admin vendor dir
    exact: true,
  },
  {
    path: ROUTES.USERS,
    Component: lazy(() => import("components/Users/UserDirectory")), // user directory
    exact: true,
  },
  {
    path: ROUTES.VENDOR,
    Component: lazy(() => import("pages/users/directory/vendor")), //  vednor directory for user
    exact: true,
  },
  {
    path: ROUTES.ADMIN_VENDOR_TYPE,
    Component: lazy(() => import("components/Admin/vendortype/fetchAll")), // admin vendor type
    exact: true,
  },
  {
    path: ROUTES.USER_SERVICES,
    Component: lazy(() => import("components/Users/UserCompanyServices")),
    exact: true,
  },
  {
    path: "/dashboard/user",
    Component: lazy(() => import("components/Dashboard/Users")), //  user dashboard
    exact: true,
  },
  {
    path: ROUTES.USER_ADD_VENDOR,
    Component: lazy(() => import("components/vendor/AddVendor")), // Add vendor
    exact: true,
  },
  {
    path: ROUTES.ADMIN_ADD_VENDOR,
    Component: lazy(() => import("components/Admin/vendor/AddVendor")),
    exact: true,
  },
  {
    path: ROUTES.EDIT_VENDOR,
    Component: lazy(() => import("components/Admin/vendor/editVendor")),
    exact: true,
  },
  {
    path: ROUTES.ADMIN_SHOW_USER,
    Component: lazy(() => import("components/Dashboard/Admin/userInfo")),
  },
  {
    path: ROUTES.ADMIN_VENDOR_BY_ID,
    Component: lazy(() =>
      import("components/Admin/vendor/fetchVendor/vendorDrawer")
    ),
  },
  {
    path: ROUTES.USER_SHOW_USER,
    Component: lazy(() => import("components/Users/UserDirectory/userInfo")),
  },
  {
    path: ROUTES.USER_VENDOR_BY_ID,
    Component: lazy(() => import("components/vendor/fetchVendor/vendorDrawer")),
  },
];

const mapStateToProps = ({ user, settings }) => ({
  routerAnimation: settings.routerAnimation,
  role: user.role,
});

const Router = ({ history, routerAnimation, role }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === "none" ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <Redirect
                          to={role === 1 ? "dashboard/admin" : "dashboard/user"}
                        />
                      )}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
