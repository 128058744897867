import { all, takeEvery, put } from "redux-saga/effects";
import UserService from "services/users";
import { notification } from "antd";
import { doApiCall } from "redux/helper";
import AdminService from "../../services/admin";
import actions from "./action";

const adminService = new AdminService();
const userService = new UserService();

export function* HANDLE_ADD_MODAL({ payload }) {
  yield put({
    type: "services/SET_STATE",
    payload: {
      isAddModalVisible: payload,
    },
  });
}

export function* HANDLE_EDIT_MODAL({ payload }) {
  yield put({
    type: "services/SET_STATE",
    payload: {
      isEditModalVisible: payload,
    },
  });
}
export function* ACTIVATE_SERVICE({ payload: { query } }) {
  const response = yield doApiCall(
    adminService.activateService,
    { id: query.id },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "services/FETCH_ALL_SERVICES",
      payload: {
        query: {
          role: "admin",
          page: query?.page,
          perPage: query?.perPage,
        },
      },
    });
    notification.success({
      message: "Activated!",
      description: "Service activated",
    });
  }
}

export function* DELETE_SERVICE({ payload: { query } }) {
  const response = yield doApiCall(
    adminService.deleteService,
    { id: query.id },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "services/FETCH_ALL_SERVICES",
      payload: {
        query: {
          role: "admin",
          page: query?.page,
          perPage: query?.perPage,
        },
      },
    });
    notification.warning({
      message: "Deactivated!",
      description: "Service deactivated",
    });
  } else {
    yield put({
      type: "services/FETCH_ALL_SERVICES",
      payload: { query: { role: "admin" } },
    });
  }
}

export function* ADD_SERVICE({ payload: { query, body } }) {
  const response = yield doApiCall(
    adminService.addService,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "services/FETCH_ALL_SERVICES",
      payload: {
        query: {
          role: "admin",
          page: query?.page,
          perPage: query?.perPage,
        },
      },
    });
    yield put({
      type: "services/HANDLE_ADD_MODAL",
      payload: false,
    });
    notification.success({
      message: "Added!",
      description: "Service is added",
    });
  }
  if (!response.success) {
    yield put({
      type: "services/HANDLE_ADD_MODAL",
      payload: true,
    });
  }
}

export function* EDIT_SERVICE({ payload: { query, body } }) {
  const response = yield doApiCall(
    adminService.editService,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "services/FETCH_ALL_SERVICES",
      payload: {
        query: {
          role: "admin",
          page: query?.page,
          perPage: query?.perPage,
        },
      },
    });
    yield put({
      type: "services/HANDLE_EDIT_MODAL",
      payload: false,
    });
    notification.success({
      message: "Updated!",
      description: "Service name updated",
    });
  }
  if (!response.success) {
    yield put({
      type: "services/HANDLE_EDIT_MODAL",
      payload: true,
    });
  }
}

export function* FETCH_ALL_SERVICES({ payload: { query } }) {
  let response;
  if (query.role === "user") {
    response = yield doApiCall(
      userService.getAllServices,
      { payload: { query } },
      actions.SET_STATE
    );
    if (response.success) {
      yield put({
        type: "services/SET_STATE",
        payload: { role: "user" },
      });
      yield put({
        type: "services/SET_STATE",
        payload: {
          services: response.data,
        },
      });
    }
  } else {
    response = yield doApiCall(
      adminService.getAllServices,
      { payload: { query } },
      actions.SET_STATE
    );
    if (response.success) {
      const { data, meta } = response;
      yield put({
        type: "services/SET_STATE",
        payload: {
          services: data,
          pagination: meta.pagination,
        },
      });
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_SERVICES, FETCH_ALL_SERVICES),
    takeEvery(actions.HANDLE_ADD_MODAL, HANDLE_ADD_MODAL),
    takeEvery(actions.HANDLE_EDIT_MODAL, HANDLE_EDIT_MODAL),
    takeEvery(actions.ACTIVATE_SERVICE, ACTIVATE_SERVICE),
    takeEvery(actions.DELETE_SERVICE, DELETE_SERVICE),
    takeEvery(actions.ADD_SERVICE, ADD_SERVICE),
    takeEvery(actions.EDIT_SERVICE, EDIT_SERVICE),
  ]);
}
