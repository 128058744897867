import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { history } from "index";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import Sidebar from "@vb/components/Sidebar";
import SupportChat from "@vb/components/SupportChat";
import style from "./style.module.scss";

// const { Footer } = Layout;
const mapStateToProps = ({ settings, user }) => ({
  logo: settings.logo,
  authorized: user.authorized,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  isAuthTopbar: settings.isAuthTopbar,
});

const AuthLayout = ({
  children,
  authorized,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  isAuthTopbar,
}) => {
  const loc = history.location;
  return (
    <Layout className="vb__layout">
      <Layout.Content>
        <Sidebar />
        <SupportChat />
        <div
          className={classNames(`${style.container}`, {
            vb__layout__squaredBorders: isSquaredBorders,
            vb__layout__cardsShadow: isCardShadow,
            vb__layout__borderless: isBorderless,
            [style.white]: authPagesColor === "white",
            [style.gray]: authPagesColor === "gray",
          })}
          style={{
            backgroundImage:
              authPagesColor === "image"
                ? "url(resources/images/content/photos/8.jpeg)"
                : "",
          }}
        >
          {isAuthTopbar && (
            <div
              className={classNames(`${style.topbar}`, {
                [style.topbarGray]: isGrayTopbar,
              })}
            >
              <div className={style.logoContainer} style={{ marginTop: 12 }}>
                <Link to="/dashboard/user">
                  <img
                    src="https://i.ibb.co/h7pkP74/logo1-removebg-preview.png"
                    alt="Squareboat"
                    style={{ width: 50, marginBottom: 12 }}
                  />
                  <strong className={style.titletext} style={{ marginLeft: 3 }}>
                    ACTIVATE
                  </strong>
                </Link>
              </div>
              {!authorized && loc.pathname === "/auth/login" && (
                <div className="d-none d-sm-block">
                  <span className="mr-2">Don&#39;t have an account?</span>
                  <Link
                    to="/auth/register"
                    className="font-size-16 vb__utils__link"
                  >
                    Sign up
                  </Link>
                </div>
              )}
            </div>
          )}
          <div className="mb-5">{children}</div>
          <div style={{ marginButtom: 100 }}>
            <strong>
              Copyright © {new Date().getFullYear()}{" "}
              <a
                href="https://squareboat.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Squareboat.com
              </a>
            </strong>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default withRouter(connect(mapStateToProps)(AuthLayout));
