import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import General14 from "@vb/widgets/WidgetsGeneral/14";
import style from "./style.module.scss";

const mapStateToProps = ({ settings }) => ({ settings });

const SupportChat = ({ settings: { isSupportChatOpen } }) => {
  // const toggleSupportChat = () => {
  //   dispatch({
  //     type: 'settings/CHANGE_SETTING',
  //     payload: {
  //       setting: 'isSupportChatOpen',
  //       value: !isSupportChatOpen,
  //     },
  //   })
  // }

  return (
    <div className={style.chat}>
      <div
        className={classNames(style.container, {
          [style.containerToggled]: isSupportChatOpen,
        })}
      >
        <General14 />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(SupportChat);
