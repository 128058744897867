import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user/reducers";
import menu from "./menu/reducers";
import settings from "./settings/reducers";
import admin from "./admin/reducers";
import iadmin from "./iadmin/reducers";
import iuser from "./iuser/reducers";
import vendor from "./vendor/reducers";
import service from "./company_services/reducers";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    iuser,
    user,
    menu,
    settings,
    iadmin,
    admin,
    vendor,
    service,
  });
