const actions = {
  SET_STATE: "vendor/SET_STATE",
  ALL_VENDOR: "vendor/ALL_VENDOR",
  ALL_VENDOR_TYPE: "vendor/ALL_VENDOR_TYPE",
  VENDOR_BY_ID: "vendor/VENDOR_BY_ID",
  SEARCH_VENDOR: "vendor/SEARCH_VENDOR",
  FETCH_ALL_VENDOR_TYPE: "vendor/FETCH_ALL_VENDOR_TYPE",
  HANDLE_MODAL_ADD: "vendor/HANDLE_MODAL_ADD",
  HANDLE_MODAL_EDIT: "vendor/HANDLE_MODAL_EDIT",
  ACTIVATE_VENDOR: "vendor/ACTIVATE_VENDOR",
  DELETE_VENDOR: "vendor/DELETE_VENDOR",
};

export default actions;
