import React from "react";
import { connect } from "react-redux";
import style from "./style.module.scss";

const mapStateToProps = ({ settings }) => ({ settings });

const Footer = () => {
  return (
    <div className={style.footer}>
      <hr />
      <strong
        style={{ textAlign: "center", width: "100%", display: "inline-block" }}
      >
        Copyright © {new Date().getFullYear()}{" "}
        <a
          href="https://squareboat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Squareboat.com
        </a>
      </strong>
    </div>
  );
};

export default connect(mapStateToProps)(Footer);
