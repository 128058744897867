// import * as qs from 'querystring';
import apiClient from "../axios";

class UserService {
  getAllUser = () => {
    return apiClient.get("admin/companyServices");
  };

  currentUser = () => {
    return apiClient.get("users/profile");
  };

  registerUser = ({ body }) => {
    return apiClient.post("auth/register", body.values);
  };

  loginUser = ({ body }) => {
    return apiClient.post("auth/login", body.values);
  };

  forgetPass = ({ body }) => {
    return apiClient.post("auth/forgetpass", body.values);
  };

  resetPass = ({ body }) => {
    return apiClient.post("auth/resetpass", body.values);
  };

  userEdit = ({ body }, token) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      return apiClient.patch("users/profile/edit", body.values, config);
    } catch (error) {
      throw error;
    }
  };

  getAllServices = () => {
    try {
      return apiClient.get(`/companyservices`);
    } catch (error) {
      throw error;
    }
  };

  searchUser = ({ body }) => {
    return apiClient.post("/auth/searchUser", { searchText: body.values });
  };

  refreshUser = () => {
    const config = {
      headers: { "X-AUTH-TOKEN": localStorage.getItem("refreshToken") },
    };
    // console.log(config);
    return apiClient.post(
      "/auth/refresh-token",
      { email: "praoon@gmail.com" },
      config
    );
  };
}

export default UserService;
