import actions from "./action";

const initialState = {
  services: [],
  pagination: {},
  role: "",
  formErrors: {},
  isAddModalVisible: false,
  isEditModalVisible: false
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state;
  }
}
