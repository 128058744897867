import { call, put, select } from "redux-saga/effects";
import { notification } from "antd";
import ROUTES from "constants/routes";
import { history } from "index";

export const getRole = (state) => state.user.role;

const errors = (err) => {
  const messages = {};
  Object.keys(err).forEach((key) => {
    const errorMessage = err[key];
    if (!errorMessage) return "";
    messages[key] =
      typeof errorMessage === "string" ? errorMessage : errorMessage.join(",");
    return 0;
  });
  return messages;
};

export function* doApiCall(service, payload, ACTION_SET_STATE) {
  yield showLoading(ACTION_SET_STATE, true);
  yield put({ type: ACTION_SET_STATE, payload: { formErrors: {} } });
  try {
    const response = yield call(service, payload);
    yield showLoading(ACTION_SET_STATE, false);
    if (response.status === 204) {
      return { success: true };
    }
    return response.data;
  } catch (e) {
    yield showLoading(ACTION_SET_STATE, false);
    const { response } = e;
    if (response) {
      const { status, data } = response;
      if (status === 422) {
        yield put({
          type: ACTION_SET_STATE,
          payload: { formErrors: errors(data.errors) },
        });
      }
      if (status === 403) {
        yield history.push(ROUTES.FORBIDDEN);
      }
      if (status === 401) {
        if (data.message === "Session Expired") {
          const role = yield select(getRole);
          console.log(role);
          // let data = yield select(stateSelectorFunction);
          yield put({
            type: "user/LOGOUT",
            payload: {
              role,
            },
          });
        } else {
          history.go(0);
        }
      }
    } else {
      notification.error({
        message: "Network Error Detected.",
        description: "Please check your internet connection.",
      });
    }
  }
  return { success: false };
}

export function* showLoading(action, loading) {
  yield put({ type: action, payload: { loading } });
}

export const formErrorFeedback = (err, key) => {
  if (err[key]) {
    return {
      hasFeedback: true,
      validateStatus: err[key] ? "error" : "",
      help: err[key],
    };
  }
  return {};
};
