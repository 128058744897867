export default async function getMenuData(payload) {
  const menuItems = [
    // VB:REPLACE-START:MENU-CONFIG

    // Admin Menu
    {
      title: "Admin Dashboard",
      key: "__dashboard",
      url: "/dashboard/admin",
      icon: "fa fa-home",
      role: 1,
    },
    {
      title: "User Directory",
      key: "__user-directory",
      url: "/admin/userdir",
      icon: "fa fa-user",
      role: 1,
    },
    {
      title: "Vendor Directory",
      key: "__vendor-directory",
      url: "/admin/vendor",
      icon: "fa fa-address-book",
      role: 1,
    },
    {
      title: "Company Services",
      key: "__company-services",
      url: "/admin/manageservices",
      icon: "fa fa-cog",
      role: 1,
    },
    {
      title: "Manage Vendor Type",
      key: "__vendor-types",
      url: "/admin/vendortype",
      icon: "fa fa-wrench",
      role: 1,
    },

    // User Menu
    {
      title: "User Dashboard",
      key: "__dashboard",
      url: "/dashboard/user",
      icon: "fa fa-home",
      role: 0,
      status: 2,
    },
    {
      title: "User Directory",
      key: "__userdir2",
      url: "/users",
      icon: "fa fa-user",
      role: 0,
      status: 1,
    },
    {
      title: "Vendor Directory",
      key: "__vendordir2",
      url: "/vendor",
      icon: "fa fa-address-book",
      role: 0,
      status: 1,
    },

    // VB:REPLACE-END:MENU-CONFIGs
  ];
  if (payload?.role === 1)
    return menuItems.filter((item) => item.role === payload.role);

  const menu = menuItems.filter((item) => item.role === payload.role);
  return menu.filter(
    (menui) => menui.status === payload.status || menui.status === 2
  );
}
