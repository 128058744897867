import actions from "./actions";

const initialState = {
  users: [],
  services: [],
  currentServices: [],
  currentuser: {},
  pagination: {},
  formErrors: {},
  view: false,
};

export default function iuserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
