import React  from 'react'
import { connect } from 'react-redux'

import { injectIntl } from 'react-intl'

const mapStateToProps = ({settings}) => ({
  locale: settings.locale,
})

const FavPages = () => {
  return (
    <div />
  )
}

export default injectIntl(connect(mapStateToProps)(FavPages))
