const actions = {
  SET_STATE: "iuser/SET_STATE",
  ADD_VENDOR: "iuser/ADD_VENDOR",
  EDIT_PROFILE: "iuser/EDIT_PROFILE",
  FETCH_USER: "iuser/FETCH_USER",
  GET_USER_BY_ID: "iuser/GET_USER_BY_ID",
  COMPANY_SERVICES: "iuser/COMPANY_SERVICES",
  PAGINATION: "iuser/PAGINATION",
  FETCH_EDIT_SERVICES: "iuser/FETCH_EDIT_SERVICES",
  ACTIVATE_SERVICE: "iuser/ACTIVATE_SERVICE",
  DELETE_SERVICE: "iuser/DELETE_SERVICE",
  COMPANY_SERVICES_SEARCH: "iuser/COMPANY_SERVICES_SEARCH",
};

export default actions;
