import { all } from "redux-saga/effects";
import user from "./user/sagas";
import menu from "./menu/sagas";
import settings from "./settings/sagas";
import admin from "./admin/sagas";
import iadmin from "./iadmin/sagas";
import iuser from "./iuser/sagas";
import vendor from "./vendor/sagas";
import service from "./company_services/sagas";

export default function* rootSaga() {
  yield all([
    iuser(),
    user(),
    menu(),
    settings(),
    admin(),
    iadmin(),
    vendor(),
    service(),
  ]);
}
