const ROUTES = {
  ADMIN_DASHBOARD: "/dashboard/admin",
  ADMIN_SERVICES: "/admin/manageservices",
  USER_LOGIN: "/auth/login",
  ADMIN_LOGIN: "/auth/admin",
  USER_EDITPROFILE: "/user/edit",
  USER_DIRECTORY: "/admin/userdir",
  USER_RESET_PASSWORD: "/auth/reset-password",
  USER_FORGOT_PASSWORD: "/auth/forgot-password",
  USER_REGISTER: "/auth/register",
  ADMIN_VENDOR: "/admin/vendor",
  USERS: "/users",
  USER_DASHBOARD: "/dashboard/user",
  VENDOR: "/vendor",
  ADMIN_VENDOR_TYPE: "/admin/vendortype",
  USER_SERVICES: "/users/usercompanyservices",
  NO_ACCESS: "/pages/",
  PAGE_NOT_FOUND: "/auth/404",
  FORBIDDEN: "/auth/403",
  INTERNAL_SERVER_ERROR: "/auth/500",
  SERVICES: "/users/companyservices",
  USER_ADD_VENDOR: "/vendor/addvendor",
  ADMIN_ADD_VENDOR: "/admin/vendor/addvendor",
  EDIT_VENDOR: "/admin/vendor/editvendor",
  ADMIN_SHOW_USER: "/admin/users/:id",
  USER_SHOW_USER: "/users/:id",
  ADMIN_VENDOR_BY_ID: "/admin/vendor/:id",
  USER_VENDOR_BY_ID: "/vendor/:id",
};

export default ROUTES;
