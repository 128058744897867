import React, { useState } from "react";
import { connect } from "react-redux";
import { SearchOutlined, RightCircleTwoTone } from "@ant-design/icons";
import { Input, Button } from "antd";
import SearchModal from "../../../../components/Search/Modal";
import style from "./style.module.scss";

let searchInput = null;
const { Search } = Input;
const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
  status: user.status,
});

const SearchUser = ({ status, dispatch, user }) => {
  const { foundUser } = user;
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [serviceObj, setServiceObj] = useState({});

  const showLiveSearch = () => {
    setShowSearch(true);
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      searchInput.focus();
    }, 100);
  };

  const changeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const handleCancel = () => {
    document.body.style.overflow = "auto";
    isModalVisible(false);
  };

  const showModal = (serviceInfo) => {
    setIsModalVisible(true);
    setServiceObj(serviceInfo);
  };

  const hideLiveSearch = () => {
    searchInput.blur();
    document.body.style.overflow = "auto";
    setShowSearch("");
    setSearchText("");
  };

  const handleNode = (node) => {
    searchInput = node;
  };

  const onSearch = (values) => {
    if (status === 1) {
      dispatch({
        type: "user/USER_SEARCH",
        payload: {
          body: {
            values,
          },
        },
      });
    }
  };

  const getProfileImage = (u) => {
    if (u.profileImage === null) {
      return "url(resources/images/content/photos/1.jpeg)";
    }
    return `url(${process.env.REACT_APP_BASE_MEDIA_URL + u.profileImage})`;
  };

  return (
    <>
      <SearchModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        serviceObj={serviceObj}
        onCancel={handleCancel}
        key={serviceObj.id}
      />
      <div className="d-inline-block mr-4" style={{ zIndex: 1 }}>
        <Input
          className={`${style.extInput} t-8`}
          placeholder="Search user"
          prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          style={{ width: 200 }}
          onFocus={showLiveSearch}
        />
        <div
          style={{ overflow: "auto" }}
          className={`${
            showSearch
              ? `${style.livesearch} ${style.livesearchVisible}`
              : style.livesearch
          }`}
          id="livesearch"
        >
          <button
            className={style.close}
            type="button"
            onClick={hideLiveSearch}
          >
            <i className="fa fa-times" />
          </button>
          <div className="container-fluid">
            <div className={style.wrapper}>
              <Search
                type="search"
                className={style.searchInput}
                value={searchText}
                onChange={changeSearchText}
                id="livesearchInput"
                autoComplete="off"
                placeholder="Search user"
                onSearch={onSearch}
                ref={handleNode}
                style={{
                  width: 200,
                }}
              />
              {!foundUser && (
                <div className={style.results}>
                  <div className={style.resultsTitle}>
                    <span>No Results Found</span>
                  </div>
                </div>
              )}
              {foundUser && status === 1 && (
                <div>
                  <div className={style.resultsTitle}>
                    <span>Pages Search Results</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      {foundUser.map((item) => {
                        return (
                          <div className={style.resultContent} key={item.uuid}>
                            <div
                              className={style.resultThumb}
                              style={{
                                backgroundImage: getProfileImage(item),
                              }}
                            >
                              #1
                            </div>
                            <div className={style.result}>
                              <div className={style.resultText}>
                                {item.name}
                              </div>
                              <div className={style.resultSource}>
                                <strong>Company Name</strong>:-{" "}
                                {item.companyName}
                                <Button
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                    showModal(item);
                                  }}
                                  icon={<RightCircleTwoTone />}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(SearchUser);
