import apiClient from "../axios";

class IAdminService {
  getAllUser = ({ payload }) => {
    return apiClient.get(
      `admin/users?page=${payload.query.page}&perPage=${payload.query.perPage}`
    );
  };

  getUSerById = ({ query }) => {
    return apiClient.get(`admin/users/${query.id}`);
  };

  getAllVendor = ({ payload }) => {
    return apiClient.get(
      `admin/vendor?page=${payload.query.page}&perPage=${payload.query.perPage}`
    );
  };

  getVendorById = ({ query }) => {
    return apiClient.get(`admin/vendor/${query.id}`);
  };

  deleteVendor = ({ query }) => {
    return apiClient.patch(`admin/vendor/${query.id}/deactivate`);
  };

  activateVendor = ({ query }) => {
    return apiClient.patch(`admin/vendor/${query.id}/activate`);
  };

  editVendor = ({ payload }) => {
    return apiClient.patch(
      `admin/vendor/${payload.body.id}/edit`,
      payload.body.values
    );
  };

  addvendortype = ({ payload }) => {
    return apiClient.post("admin/vendortype", payload.body.values);
  };

  fetchvendortype = () => {
    return apiClient.get("admin/vendortype");
  };

  fetchAllVendorType = () => {
    return apiClient.get("admin/vendortype/all");
  };

  activateUser = ({ query }) => {
    return apiClient.patch(`/admin/users/${query.id}/activate`);
  };

  deleteUser = ({ query }) => {
    return apiClient.patch(`admin/users/${query.id}/deactivate`);
  };

  managevendorType = ({ query }) => {
    return apiClient.get(
      `admin/vendortype?page=${query.page}&perPage=${query.perPage}`
    );
  };

  deleteVendortype = ({ payload }) => {
    return apiClient.patch(`admin/vendortype/${payload.body.id}/deactivate`);
  };

  activatevendortype = ({ payload }) => {
    return apiClient.patch(`admin/vendortype/${payload.body.id}/activate`);
  };

  editvendortype = ({ payload }) => {
    return apiClient.patch(
      `admin/vendortype/${payload.body.id}/edit`,
      payload.body.name
    );
  };

  fetchCompanyServices = ({ query }) => {
    return apiClient.get(`admin/users/${query.id}/companyservices`);
  };

  addvendor = ({ payload }) => {
    return apiClient.post("admin/vendor", payload.body.values);
  };
}

export default IAdminService;
