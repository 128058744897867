import { takeEvery, all, put } from "redux-saga/effects";
import { doApiCall } from "redux/helper";
import { notification } from "antd";
import actions from "./action";
import AdminServices from "../../services/admin";

const adminServices = new AdminServices();

export function* GET_ALL_USERS({ payload: { pager } }) {
  const response = yield doApiCall(
    adminServices.getAllUsers,
    { payload: { pager } },
    actions.SET_STATE
  );
  if (response.success) {
    const users = response.data;
    const { pagination } = response.meta;
    yield put({
      type: "admin/SET_STATE",
      payload: {
        users,
        pagination,
      },
    });
  }
  if (!response.success) {
    yield put({
      type: "admin/SET_STATE",
      payload: {
        loading: false,
      },
    });
  }
}

export function* GET_UNVERIFIED_USERS({ payload: { pager } }) {
  const response = yield doApiCall(
    adminServices.getUnverifiedUsers,
    { payload: { pager } },
    actions.SET_STATE
  );
  if (response.success) {
    const unverifiedUsers = response.data;
    const { pagination } = response.meta;
    yield put({
      type: "admin/SET_STATE",
      payload: {
        unverifiedUsers,
        pagination,
      },
    });
  }
  if (!response.success) {
    yield put({
      type: "admin/SET_STATE",
      payload: {
        loading: false,
      },
    });
  }
}

export function* GET_UNVERIFIED_VENDOR({ payload: { pager } }) {
  const response = yield doApiCall(
    adminServices.getUnverifiedVendor,
    { pager },
    actions.SET_STATE
  );
  if (response.success) {
    const { data, meta } = response;
    yield put({
      type: "admin/SET_STATE",
      payload: {
        unverifiedVendors: data,
        vPagination: meta.pagination,
      },
    });
  }
}

export function* APPROVE_USER({ payload: { query, pager } }) {
  const response = yield doApiCall(
    adminServices.approveUser,
    { payload: { query } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "admin/GET_ALL_USERS",
      payload: {
        pager,
      },
    });
    notification.success({
      message: "Activated",
      description: "User has been activated.",
    });
  }
}

export function* DEACTIVATE_USER({ payload: { query, pager } }) {
  const response = yield doApiCall(
    adminServices.disapproveUser,
    { payload: { query } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "admin/GET_ALL_USERS",
      payload: {
        pager,
      },
    });
    notification.warning({
      message: "Deactivated",
      description: "User has been deactivated.",
    });
  }
}

export function* IAPPROVE_USER({ payload: { query, pager } }) {
  const response = yield doApiCall(
    adminServices.approveUser,
    { payload: { query } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "admin/GET_UNVERIFIED_USERS",
      payload: {
        pager,
      },
    });
    notification.success({
      message: "Activated",
      description: "User has been activated.",
    });
  }
}

export function* IDEACTIVATE_USER({ payload: { query, pager } }) {
  const response = yield doApiCall(
    adminServices.disapproveUser,
    { payload: { query } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "admin/GET_UNVERIFIED_USERS",
      payload: {
        pager,
      },
    });
    notification.warning({
      message: "Deactivated",
      description: "User has been deactivated.",
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_USERS, GET_ALL_USERS),
    takeEvery(actions.APPROVE_USER, APPROVE_USER),
    takeEvery(actions.DEACTIVATE_USER, DEACTIVATE_USER),
    takeEvery(actions.IAPPROVE_USER, IAPPROVE_USER),
    takeEvery(actions.IDEACTIVATE_USER, IDEACTIVATE_USER),
    takeEvery(actions.GET_UNVERIFIED_USERS, GET_UNVERIFIED_USERS),
    takeEvery(actions.GET_UNVERIFIED_VENDOR, GET_UNVERIFIED_VENDOR),
  ]);
}
