import actions from "./actions";

const initialState = {
  pagination: {},
  vendor: [],
  vendorType: [],
  formErrors: {},
  currentvendor: {},
  serviceObj: {},
  MODAL_ADD: false,
  MODAL_EDIT: false,
};

export default function vendorReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
