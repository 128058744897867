import actions from "./action";

const initialState = {
  pagination: {},
  vPagination: {},
  users: [],
  unverifiedUsers: [],
  unverifiedVendors: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
