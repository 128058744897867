const actions = {
  ADMIN_LOGIN_AUTH: 'user/ADMIN_LOGIN_AUTH',
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGET_PASS: 'user/FORGET_PASS',
  RESET_PASS: 'user/RESET_PASS',
  EDIT_PROFILE: 'user/EDIT_PROFILE',
  USER_SEARCH: 'user/USER_SEARCH'
}

export default actions
