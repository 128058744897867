import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Drawer, Card, Button, Descriptions, Space, Image, Tag } from "antd";

const mapStateToProps = ({ dispatch }) => ({
  dispatch,
});

const SearchModal = ({
  dispatch,
  isModalVisible,
  setIsModalVisible,
  serviceObj,
}) => {
  const services = useSelector((state) => state.iuser.currentServices);
  const role = useSelector((state) => state.user.role);

  useEffect(async () => {
    if (isModalVisible) {
      onload();
    }
  }, [isModalVisible]);

  const onload = () => {
    if (role === 0) {
      dispatch({
        type: "iuser/COMPANY_SERVICES_SEARCH",
        payload: {
          query: {
            id: serviceObj.id,
          },
        },
      });
    } else {
      dispatch({
        type: "iadmin/COMPANY_SERVICES",
        payload: {
          query: {
            id: serviceObj.id,
          },
        },
      });
    }
  };

  const getProfileImage = () => {
    if (serviceObj.profileImage === null) {
      return "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png";
    }
    return process.env.REACT_APP_BASE_MEDIA_URL + serviceObj.profileImage;
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Drawer
        className="user-drawer"
        title={serviceObj.name}
        width={700}
        placement="right"
        onClose={handleCancel}
        visible={isModalVisible}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={handleCancel}>Close</Button>
          </Space>
        }
      >
        <Card
          cover={
            <Image
              style={{ objectFit: "contain" }}
              alt="profileImage"
              src={getProfileImage()}
            />
          }
        />

        <Descriptions bordered>
          <Descriptions.Item span={12} label="Name">
            {serviceObj.name}
          </Descriptions.Item>
          <Descriptions.Item span={12} label="Email">
            {serviceObj.email}
          </Descriptions.Item>
          <Descriptions.Item span={12} label="Phone Number">
            {serviceObj.phoneNumber}
          </Descriptions.Item>
          <Descriptions.Item span={12} label="Company">
            {serviceObj.companyName}
          </Descriptions.Item>
          <Descriptions.Item span={12} label="Designation">
            {serviceObj.designation}
          </Descriptions.Item>
          <Descriptions.Item span={12} label="Services">
            {services.map((element) => {
              return (
                <Tag style={{ margin: 7 }} color="blue" key={element.id}>
                  {element.companyService}
                </Tag>
              );
            })}
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  );
};
export default connect(mapStateToProps)(SearchModal);
