import { all, put, call, takeEvery } from "redux-saga/effects";
import getMenuData from "services/menu";
import actions from "./actions";

export function* GET_DATA({ payload }) {
  const menuData = yield call(getMenuData, payload);
  yield put({
    type: "menu/SET_STATE",
    payload: {
      menuData,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA), // run once on app load to fetch menu data
  ]);
}
