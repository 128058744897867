import axios from "axios";
import store from "store";
import { notification } from "antd";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
});

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get("token");
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers.AccessToken = accessToken;
  }
  return request;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Errors handling
    const { response } = error;
    if (response) {
      const { data } = response;
      if (data) {
        notification.warning({
          message: data.message,
        });
      }
    }
    throw error;
  }
);

export default apiClient;
