const actions = {
  SET_STATE: "admin/SET_STATE",
  ADMIN_LOGIN: "admin/ADMIN_LOGIN",
  LOAD_CURRENT_ACCOUNT: "admin/LOAD_CURRENT_ACCOUNT",
  GET_ALL_USERS: "admin/GET_ALL_USERS",
  APPROVE_USER: "admin/APPROVE_USER",
  DEACTIVATE_USER: "admin/DEACTIVATE_USER",
  GET_UNVERIFIED_USERS: "admin/GET_UNVERIFIED_USERS",
  GET_UNVERIFIED_VENDOR: "admin/GET_UNVERIFIED_VENDOR",
  IAPPROVE_USER: "admin/IAPPROVE_USER",
  IDEACTIVATE_USER: "admin/IDEACTIVATE_USER",
};

export default actions;
