import { all, takeEvery, put } from "redux-saga/effects";
import { notification } from "antd";
import { history } from "index";
import IUserService from "services/iuser";
import { doApiCall } from "redux/helper";
import actions from "./actions";

const iUserService = new IUserService();

export function* FETCH_USER({ payload: { query } }) {
  const response = yield doApiCall(
    iUserService.getAllUser,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data, meta } = response;
    yield put({
      type: "iuser/SET_STATE",
      payload: {
        users: data,
        pagination: meta.pagination,
      },
    });
  }
}

export function* COMPANY_SERVICES({ payload: { query } }) {
  const response = yield doApiCall(
    iUserService.fetchCompanyServices,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iuser/SET_STATE",
      payload: {
        services: data,
      },
    });
  }
}

export function* COMPANY_SERVICES_SEARCH({ payload: { query } }) {
  const response = yield doApiCall(
    iUserService.fetchCompanyServices,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iuser/SET_STATE",
      payload: {
        currentServices: data,
      },
    });
  }
}

export function* ADD_VENDOR({ payload: { body } }) {
  const response = yield doApiCall(
    iUserService.addvendor,
    { payload: { body } },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "vendor/ALL_VENDOR",
      payload: {
        query: {
          page: 1,
          perPage: 15,
        },
      },
    });
    notification.success({
      message: "Vendor Added Successfully",
      description: "New Vendor is Added!",
    });
    history.push("/vendor");
  }
}

export function* FETCH_EDIT_SERVICES() {
  const response = yield doApiCall(
    iUserService.getEditCompanyServices,
    {},
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iuser/SET_STATE",
      payload: {
        services: data.map((d, i) => ({ ...d, index: i + 1 })),
      },
    });
  }
}

export function* ACTIVATE_SERVICE({ payload: { body } }) {
  const response = yield doApiCall(
    iUserService.activateService,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "iuser/FETCH_EDIT_SERVICES",
    });
    notification.success({
      message: "Service Added",
      description: "Company services is successfully added",
    });
  }
}

export function* DELETE_SERVICE({ payload: { body } }) {
  const response = yield doApiCall(
    iUserService.deleteService,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "iuser/FETCH_EDIT_SERVICES",
    });
    notification.warning({
      message: "Service Deleted",
      description: "Company services is successfully removed",
    });
  }
}

export function* EDIT_PROFILE({ payload: { body } }) {
  const response = yield doApiCall(
    iUserService.userEdit,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    const {
      id,
      name,
      email,
      companyName,
      phoneNumber,
      designation,
      profileImage,
    } = data;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id,
        name,
        companyName,
        phoneNumber,
        designation,
        role: "user",
        email,
        avatar: profileImage,
        authorized: true,
        loading: false,
      },
    });
    notification.success({
      message: "Profile Edited",
      description: "New Details have been saved!",
    });
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
    history.push("/");
  }
}

export function* GET_USER_BY_ID({ payload: { query } }) {
  const response = yield doApiCall(
    iUserService.getUserById,
    { query },
    actions.SET_STATE
  );
  if (response.success) {
    const { data } = response;
    yield put({
      type: "iuser/SET_STATE",
      payload: {
        currentuser: data,
      },
    });
    yield put({
      type: "iuser/COMPANY_SERVICES",
      payload: {
        query,
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_USER, FETCH_USER),
    takeEvery(actions.COMPANY_SERVICES, COMPANY_SERVICES),
    takeEvery(actions.ACTIVATE_SERVICE, ACTIVATE_SERVICE),
    takeEvery(actions.DELETE_SERVICE, DELETE_SERVICE),
    takeEvery(actions.FETCH_EDIT_SERVICES, FETCH_EDIT_SERVICES),
    takeEvery(actions.ADD_VENDOR, ADD_VENDOR),
    takeEvery(actions.EDIT_PROFILE, EDIT_PROFILE),
    takeEvery(actions.COMPANY_SERVICES_SEARCH, COMPANY_SERVICES_SEARCH),
    takeEvery(actions.GET_USER_BY_ID, GET_USER_BY_ID),
  ]);
}
