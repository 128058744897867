const actions = {
  SET_STATE: "iadmin/SET_STATE",
  USER_DIRECTORY: "iadmin/USER_DIRECTORY",
  GET_ALL_USERS: "iadmin/GET_ALL_USERS",
  ADD_VENDOR: "iadmin/ADD_VENDOR",
  ADD_VENDOR_TYPE: "iadmin/ADD_VENDOR_TYPE",
  FETCH_ALL_VENDOR_TYPE: "iadmin/FETCH_ALL_VENDOR_TYPE",
  GET_ALL_VENDOR: "iadmin/GET_ALL_VENDOR",
  DELETE_VENDOR: "iadmin/DELETE_VENDOR",
  DELETE_VENDOR_TYPE: "iadmin/DELETE_VENDOR_TYPE",
  COMPANY_SERVICES: "iadmin/COMPANY_SERVICES",
  ACTIVATE_VENDOR: "iadmin/ACTIVATE_VENDOR",
  ACTIVATE_VENDOR_TYPE: "iadmin/ACTIVATE_VENDOR_TYPE",
  EDIT_VENDOR: "iadmin/EDIT_VENDOR",
  EDIT_VENDOR_TYPE: "iadmin/EDIT_VENDOR_TYPE",
  VENDOR_BY_ID: "iadmin/VENDOR_BY_ID",
  PAGINATION: "iadmin/PAGINATION",
  MANAGE_VENDOR_TYPE: "iadmin/MANAGE_VENDOR_TYPE",
  FETCH_VENDOR_TYPE: "iadmin/FETCH_VENDOR_TYPE",
  ACTIVATE_USER: "iadmin/ACTIVATE_USER",
  DELETE_USER: "iadmin/DELETE_USER",
  GET_USER_BY_ID: "iadmin/GET_USER_BY_ID",
};

export default actions;
