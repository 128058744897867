import { all, takeEvery, put, call } from "redux-saga/effects";
import { notification } from "antd";
import { history } from "index";
import { doApiCall } from "redux/helper";
import AdminService from "services/admin";
import UserService from "services/users";
import store from "store";
import actions from "./actions";

const userService = new UserService();
const adminService = new AdminService();

export function* LOGIN({ payload: { body } }) {
  const response = yield doApiCall(
    userService.loginUser,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    const { authToken, refreshToken } = response.data;
    notification.success({
      message: "Logged In",
      description: "You have successfully logged in!",
    });
    store.set("token", authToken);
    store.set("refreshToken", refreshToken);
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
    yield put({
      type: "user/SET_STATE",
      payload: {
        authorized: true,
      },
    });
    history.push("/dashboard/user");
  }
}

export function* REGISTER({ payload: { body } }) {
  const response = yield doApiCall(
    userService.registerUser,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    const { authToken, refreshToken } = response.data;
    store.set("refreshToken", refreshToken);
    store.set("token", authToken);
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
    history.push("/");
    notification.success({
      message: "Success",
      description: "User has registered.",
    });
    yield put({
      type: "user/SET_STATE",
      payload: {
        authorized: true,
      },
    });
    history.push("/dashboard/user");
  }
}

export function* ADMIN_LOGIN_AUTH({ payload: { body } }) {
  const response = yield doApiCall(
    adminService.adminLogin,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    const { authToken, refreshToken } = response.data;
    store.set("refreshToken", refreshToken);
    store.set("token", authToken);
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
    notification.success({
      message: "Logged In",
      description: "You have successfully logged in!",
    });
    yield put({
      type: "user/SET_STATE",
      payload: {
        authorized: true,
      },
    });
    history.push("/dashboard/admin");
  }
}

export function* USER_SEARCH({ payload: { body } }) {
  const response = yield doApiCall(
    userService.searchUser,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    yield put({
      type: "user/SET_STATE",
      payload: {
        foundUser: response.data,
      },
    });
  }
}

export function* FORGET_PASS({ payload: { body } }) {
  if (!store.get()) {
    const response = yield doApiCall(
      userService.forgetPass,
      { body },
      actions.SET_STATE
    );
    if (response.success) {
      history.push("/auth/reset-password");
    }
  }
}

export function* RESET_PASS({ payload: { body } }) {
  const response = yield doApiCall(
    userService.resetPass,
    { body },
    actions.SET_STATE
  );
  if (response.success) {
    notification.success({
      message: "Password Changed!",
      description: "New Password is saved.",
    });
    history.push("/auth/login");
  }
}

export function* EDIT_PROFILE({ payload: { body } }) {
  const response = yield doApiCall(
    userService.userEdit,
    { body },
    actions.SET_STATE
  );

  if (response.success) {
    const {
      id,
      name,
      email,
      companyName,
      phoneNumber,
      designation,
    } = response.data;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id,
        name,
        companyName,
        phoneNumber,
        designation,
        role: "user",
        email,
        avatar: "",
        authorized: true,
        loading: false,
      },
    });
    notification.success({
      message: "Profile Edited",
      description: "New Details have been saved!",
    });
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
    history.push("/");
  }
  if (!response) {
    notification.error({
      message: "Failed!",
      description: "phone number already registered",
    });
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
      },
    });
  }
}

export function* REFRESH_TOKEN() {
  if (localStorage.getItem("refreshToken")) {
    const response = yield doApiCall(
      userService.refreshUser,
      {},
      actions.SET_STATE
    );
    if (response.success) {
      const { authToken } = response.data;
      store.set("token", authToken);
    }
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  if (!store.get("token")) {
    yield put({
      type: "user/SET_STATE",
      payload: {
        id: "",
        name: "",
        role: "",
        designation: "",
        companyName: "",
        phoneNumber: "",
        email: "",
        avatar: "",
        authorized: false,
        loading: false,
      },
    });
    return;
  }
  try {
    const response = yield call(userService.currentUser, {});
    if (response) {
      const {
        id,
        email,
        name,
        phoneNumber,
        role,
        status,
        companyName,
        designation,
        profileImage,
      } = response.data.data;
      yield put({
        type: "user/SET_STATE",
        payload: {
          id,
          name,
          email,
          phoneNumber,
          status,
          companyName,
          designation,
          avatar: profileImage,
          role: role === 0 ? 0 : 1,
          authorized: true,
        },
      });
      yield put({
        type: "menu/GET_DATA",
        payload: {
          role,
          status,
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false,
    },
  });
}

export function* LOGOUT({ payload }) {
  localStorage.clear();
  const role = payload;
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      companyName: "",
      designation: "",
      phoneNumber: "",
      role: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false,
    },
  });
  if (role === 0) {
    history.push("/auth/login");
  } else history.push("/auth/admin");
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.ADMIN_LOGIN_AUTH, ADMIN_LOGIN_AUTH),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.EDIT_PROFILE, EDIT_PROFILE),
    takeEvery(actions.FORGET_PASS, FORGET_PASS),
    takeEvery(actions.RESET_PASS, RESET_PASS),
    takeEvery(actions.USER_SEARCH, USER_SEARCH),
    REFRESH_TOKEN(),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
