import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale,
})

const LanguageSwitcher = () => {
  return (
    <div />
  )
}

export default connect(mapStateToProps)(LanguageSwitcher)
